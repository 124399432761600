import isEmpty from "lodash/isEmpty";
import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Typo from "../Typo";

import placeholder from "~/assets/images/placeholder.png";
import Image from "~/components/atoms/Image";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface ImageResponsiveProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  className?: string;
  imageData?: IMediaImageUrls;
  maxWidth?: number;
  zoom?: number;
  displayDisclaimer?: boolean;
  disclaimerClass?: string;
  pictureClassName?: string;
}

const getZoomClass = (zoomLevel: number) => {
  // Map zoom level to Tailwind CSS scale classes
  const zoomClasses: { [key: number]: string } = {
    1: "scale-[1]",
    1.3: "scale-[1.3]",
    2: "scale-[2]",
    3: "scale-[3]",
  };

  // Get the closest match in zoomClasses or default to 'scale-[1]'
  if (zoomLevel <= 1) return zoomClasses[1];
  if (zoomLevel === 1.3) return zoomClasses[1.3];
  if (zoomLevel <= 2 && zoomLevel !== 1.3) return zoomClasses[2];
  return zoomClasses[3];
};

const ImageResponsive: React.FC<ImageResponsiveProps> = ({
  alt = "",
  className = "",
  imageData,
  maxWidth,
  displayDisclaimer = false,
  disclaimerClass = "",
  pictureClassName = "",
  zoom = 1, // Default zoom level to 1 if undefined
  ...props
}) => {
  const { t } = useTranslation();
  const isEmptyImageData =
    isEmpty(imageData) ||
    (!isEmpty(imageData) &&
      (!Array.isArray(imageData?.urls) || imageData?.urls?.length === 0));

  const [zoomClass, setZoomClass] = useState(getZoomClass(zoom));

  useEffect(() => {
    setZoomClass(getZoomClass(zoom));
  }, [zoom]);

  if (isEmptyImageData)
    return (
      <Image
        isLocalImage
        url={placeholder}
        className={cn("h-full w-full", className)}
      />
    );

  // Filter and sort URLs by width in ascending order
  const filteredUrls = [...(imageData?.urls || [])]
    .filter((image) => !maxWidth || image.width <= maxWidth)
    .sort((a, b) => a.width - b.width);

  const altText = `Image for ${imageData.relation} ${alt}`;

  // Get the highest quality image for the default src
  const highestQualityImage = filteredUrls[filteredUrls.length - 1];

  return (
    <picture className={cn("h-full w-full", pictureClassName)}>
      {filteredUrls.map((image, index) => (
        <source
          key={index}
          srcSet={ENV.IMAGE_DOMAIN_URL + image?.url}
          media={`(max-width: ${image?.width}px)`}
        />
      ))}
      <img
        className={cn(
          "h-auto w-auto transform object-cover transition-transform duration-300",
          className,
          zoomClass
        )}
        src={ENV.IMAGE_DOMAIN_URL + highestQualityImage?.url}
        alt={altText}
        loading="lazy"
        {...props}
      />
      {displayDisclaimer && (
        <Typo
          tag={ETypoTag.P}
          variant={ETypoVariant.HEADER_14}
          color={ETypoColor.WHITE}
          className={cn(
            "absolute bottom-6 left-1/2 z-auto -translate-x-1/2 transform truncate text-white",
            disclaimerClass
          )}
        >
          {t("disclaimer_notification")}
        </Typo>
      )}
    </picture>
  );
};

export default ImageResponsive;
